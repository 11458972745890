.Connect-App--content-3{
    margin-top: 90px !important;
}
.deni-react-treeview-container{
    width: 100% !important;
    height: auto;
    padding: 20px;
    background-color: #ffffff !important;
    border-color: #ffffff !important;
}
.action-buttons-container-text{
    padding: 10px;
    font-size: 18px;
}
.deni-react-treeview-item-container{
    padding: 15px;
}
.action-buttons-container .action-button{
    margin-top: 10px;
}